import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "error-page" }
const _hoisted_2 = { class: "center-block" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("h1", null, _toDisplayString(_ctx.$t('_errorCodes.somethingWentWrong')), 1),
      _createVNode(_component_Button, {
        text: _ctx.$t('_header.backToDashboard'),
        onOnClick: _ctx.goToDashboard,
        class: "header_button",
        buttonType: "dark arrow--left"
      }, null, 8, ["text", "onOnClick"])
    ])
  ]))
}